import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import CustomScrollBar from 'common/components/customScroll';
import { CONSTANTS } from 'features/floatingTicketPanel/utils/constants';
import { isRtl } from '../../common/utils/utils';

export const StyledNavbar = styled('div')`
  background-color: ${(props) => props.theme.palette.generic.primary};
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.collapsed {
    width: ${CONSTANTS.NAV_BAR_COLLAPSED_WIDTH}px;
    max-height: 100%;
    .vertical-scrollbar {
      right: -6px !important;
    }
    .copilot-button {
      padding-left: 15px;
    }
  }

  &.expanded {
    width: ${CONSTANTS.NAV_BAR_EXPANDED_WIDTH}px;
    max-height: 100%;
    .vertical-scrollbar {
      right: 0 !important;
      &::-moz-scrollbar-track {
        width: 12px;
        scrollbar-width: thin;
      }
      div {
        background-color: #e6e6e6 !important;
      }
    }
  }
  .copilot-button {
    justify-content: start;
    gap: 12px;
    font-size: 14px;
    line-height: 1;
    color: ${(props) => props.theme.palette.text.title_txt};
    padding: 12px 8px 12px 24px;
    text-transform: none;
    width: 220px;
    border-radius: 0 30px 30px 0;
    margin-bottom: -8px;
    z-index: 1;
    &:hover {
      background-color: ${(props) => props.theme.palette.grey.bt_alt_grey_light};
    }
  }
`;

export const StyledScrollBar = styled(CustomScrollBar)(({theme}) => ({
  height: 'calc(100% - 75px) !important',
  marginBottom: '20px !important',
  '& .scroll-bar': {
    marginLeft: isRtl(theme.direction) && '0 !important',
  },
}));

export const StyledDivider = styled(Divider)`
  border-color: ${(props) => props.theme.palette.background.pages_background};
  padding-top: 0;
  margin-bottom: 24px;
`;
export const StyledBottomDivider = styled(Divider)`
  border-color: ${(props) => props.theme.palette.background.pages_background};
  padding-top: 0;
`;

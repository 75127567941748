import { CATEGORIES } from 'features/header/categories/constants';
import { convertFromClientField } from 'common/utils/fieldUtils';
import { QUERIES_KEYS } from 'constant';
import {
  GRID,
  COLUMN_FILTER_TITLE,
  COLUMN_FILTER_TYPES,
  COLUMN_FILTER_CATEGORY,
  FILTER_MODEL_TYPES,
} from './constants';

export const getLastRowIndex = (countTotal, endRow) =>
  endRow >= countTotal || endRow >= GRID.MAX_ROWS_ALLOWED_NUM ? Math.min(countTotal, endRow) : -1;

export const getCacheBlockNumForRows = ({ gridRef, startRow, endRow }) => {
  if (gridRef.current) {
    const cache = gridRef.current.api.getCacheBlockState();

    return Object.keys(cache).find(
      (blockNum) => cache[blockNum].startRow === startRow && cache[blockNum].endRow === endRow,
    );
  }
};

const setType = (item) => {
  switch (item.field) {
    case COLUMN_FILTER_TITLE:
      return COLUMN_FILTER_TYPES?.contains;
    default:
      return item.type;
  }
};

const setField = (fieldName) => {
  switch (fieldName) {
    case COLUMN_FILTER_CATEGORY.primaryCategory:
      return CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY;
    case COLUMN_FILTER_CATEGORY.secondaryCategory:
      return CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY;
    case COLUMN_FILTER_CATEGORY.thirdLevelCategory:
      return CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY;
    default:
      return fieldName;
  }
};

// This is a temp function for the 'priority' and 'urgency' columns to sort correctly. After the backend fix 'valueKey' this function should be removed.
const fixSortOrder = (colId, initialSort) => {
  if (colId === 'priority' || colId === 'urgency') {
    if (initialSort === 'desc') return 'asc';
    return 'desc';
  }
  return initialSort;
};

export const sortsClientToServer = (sortModel) =>
  sortModel?.map((clientSort, sortIndex) => ({
    colId: clientSort.colId,
    sort: fixSortOrder(clientSort.colId, clientSort.sort),
    sortIndex,
  })) || [];

export const filtersClientToServer = (filterModel) =>
  filterModel?.map((item) => ({
    ...item,
    fieldType: item.fieldType,
    field: setField(item.field),
    type: setType(item),
  })) || [];

export const searchRequestClientToServer = (filterModel, sortModel) => ({
  sorts: sortsClientToServer(sortModel),
  filters: filtersClientToServer(filterModel),
});

export const getRequestFromParams = (searchSrParams, selectedTickets) => {
  if (searchSrParams) {
    const withSearch = searchSrParams.filterModel?.some((filter) => filter.type === FILTER_MODEL_TYPES.SEARCH);
    const includedSrIds = selectedTickets ? selectedTickets.map((e) => e.id) : [];

    return {
      from: searchSrParams.startRow,
      limit: Math.min(searchSrParams.endRow, GRID.MAX_ROWS_ALLOWED_NUM) - searchSrParams.startRow,
      columnConfiguration: searchSrParams?.columnsOrder.map((col) => convertFromClientField(col)),
      ...(withSearch && includedSrIds?.length ? { includedSrIds } : {}),
      ...searchRequestClientToServer(searchSrParams.filterModel, searchSrParams.sortModel),
    };
  }
  return {};
};

export const removeMismatchedElements = (a, b) => {
  let similarArray = [];
  // 'archive' is a static column which is not a part of columnsConfig thus it will not be present in 'b' variable
  similarArray = a.filter(
    (elementA) => elementA.field === 'archive' || b.some((elementB) => elementA.field === elementB.fieldName),
  );
  return similarArray;
};

export const getQueryKeyFromParams = (params) => {
  const searchParams = [QUERIES_KEYS.QUEUE_DATA];
  if (params) {
    searchParams.push({
      startRow: params.startRow,
      endRow: params.endRow,
      sortModel: params.sortModel,
      filterModel: params.filterModel,
      columnsOrder: params.columnsOrder
        ? [...params.columnsOrder].sort((col1, col2) => {
            if (col1.id < col2.id) {
              return -1;
            }
            if (col1.id > col2.id) {
              return 1;
            }
            return 0;
          })
        : undefined, // columns that changed order will not invoke a new request (same cache - reordering in client)
    });
  }

  return searchParams;
};

export const convertFilterToGridFilter = (filter, allFilters) => {
  const values = [...filter.values];
  if (values.length === 0 && filter.valueClasses?.length > 0) {
    values.push('noValues'); // hack for filters with valueClasses to be catched by the ag grid filter model api
  }
  let field = filter.field;
  let name = filter.name;
  let subFilters = { [field]: filter };
  if (field === 'assignedGroup') {
    field = 'assignee'; // hack for assignedGroup filter to be catched by the ag grid filter model api (because both filters are on same column - assignee)
    name = 'assignee';
    const assigneeFilter = allFilters.find((filter) => filter.field === 'assignee');
    if (assigneeFilter) {
      subFilters = { ...subFilters, assignee: assigneeFilter };
    }
  } else if (field === 'assignee') {
    const assignedGroupFilter = allFilters.find((filter) => filter.field === 'assignedGroup');
    if (assignedGroupFilter) {
      subFilters = { ...subFilters, assignedGroup: assignedGroupFilter };
    }
  }
  return { ...filter, name, field, values, fieldType: { ...filter.fieldType, subFilters } };
};

export const convertFiltersToGridFilterModel = (filters) =>
  filters?.reduce((acc, filter) => {
    const convertedFilter = convertFilterToGridFilter(filter, filters);
    acc[convertedFilter.field] = convertedFilter;
    return acc;
  }, {});

export const convertGridFiltersToFilters = (gridFilters) =>
  Object.values(gridFilters)
    .filter((filter) => typeof filter?.filterType?.subFilters === 'object' && filter.filterType.subFilters !== null)
    .flatMap((gridFilter) => Object.values(gridFilter.filterType.subFilters));

export const prepareFilterDataForSearch = (gridFilters, searchQueueText) => {
  const result = [];
  if (!(gridFilters || searchQueueText)) return undefined;
  if (gridFilters) {
    result.push(...convertGridFiltersToFilters(gridFilters));
  }
  if (searchQueueText) {
    result.push({
      type: FILTER_MODEL_TYPES.SEARCH,
      values: [searchQueueText],
    });
  }
  return result;
};

const decreaseNumberToLessThanblockSize = (number, blockSize) => {
  // Calculate the number of times 50 can be subtracted from the input number
  const timesToSubtract = Math.floor(number / blockSize);
  // Calculate the remaining value after subtracting 50 multiple times
  const remainder = number % blockSize;
  // Calculate the final result by subtracting 50 * timesToSubtract from the original number
  const result = number - blockSize * timesToSubtract;
  // Return the result if the remainder is less than 50, otherwise, return 0
  return remainder < blockSize ? result : 0;
};
const nearestHigherNumberDivisibleByblockSize = (number, blockSize) => {
  // Calculate the remainder when dividing the number by 50
  const remainder = number % blockSize;
  // If the remainder is 0, the number is already divisible by 50
  if (remainder === 0) {
    return number;
  }
  // Calculate the difference needed to reach the next multiple of 50
  const difference = blockSize - remainder;
  // Return the number plus the difference to reach the next multiple of 50
  return number + difference;
};

export const isOneStepToNextPage = ({ currentPage, pageSize, blockSize }) => {
  if (currentPage === 1) {
    return {
      fetchNextPage: false,
    };
  }
  const minAmountOfRowsForNexPage = blockSize - pageSize;
  const totalRowsViewied = currentPage * pageSize;
  const rowsViewedPerBlock = decreaseNumberToLessThanblockSize(totalRowsViewied, blockSize);
  let params = {};
  const fetchNextPage = rowsViewedPerBlock > minAmountOfRowsForNexPage;
  if (fetchNextPage) {
    const nearestHigher = nearestHigherNumberDivisibleByblockSize(totalRowsViewied, blockSize);
    params = {
      startRow: nearestHigher,
      endRow: nearestHigher + blockSize,
    };
  }
  return {
    fetchNextPage,
    ...params,
  };
};

import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  return {
    notExistInTemplateMessage: texts?.['spaces.field.tooltip.notInTemplate'] || 'Field not found in template',
    submitUser: texts?.['spaces.useColumnDefinitions.submitUser'],
    open: texts?.['spaces.cellRender.openSr'],
    notSavedModalMessage: texts?.['spaces.changes.fieldsNotSavedModal.description'],
    notSavedByPermissionMessage: texts?.['spaces.changes.fieldsNotSavedModal.noPermission'],
    notAvailableField: texts?.['spaces.queue.notAvailableField.title'],
    notAvailableFieldText: texts?.['spaces.queue.notAvailableField.text'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
    migrationFailedMessage:
      texts?.['spaces.migration.error'] ||
      'This ticket failed to be optimized for Spaces and is displayed in classic view.',
    of: texts?.['spaces.of'] || 'of',
    yesText: texts?.['spaces.prompt.yes'],
    noText: texts?.['spaces.prompt.no'],
    hyphens: texts?.['spaces.template.fields.text.hyphens'],
    none: texts?.['spaces.template.fields.text.none'],
    descriptionPlaceholder: texts?.['spaces.template.fields.description.placeholder'],
    fieldDeletedFailed: texts?.['spaces.templateBuilder.fieldDeletedFailed'],
    fieldDeletedSuccessfully: texts?.['spaces.templateBuilder.fieldDeletedSuccessfully'],
    deleteFieldWarning: texts?.['spaces.templateBuilder.deleteFieldWarning'],
    removeField: texts?.['spaces.templateBuilder.removeField'],
    deleteAction: texts?.['spaces.templateBuilder.deleteAction'],
    moreDetails: texts?.['spaces.templateBuilder.moreDetails'],
    clear: texts?.['spaces.datePicker.dueDate.clear'],
    remove: texts?.['spaces.templateBuilder.remove'],
    dontRemove: texts?.['spaces.templateBuilder.dontRemove'],
  };
}

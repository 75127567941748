import { createSlice } from '@reduxjs/toolkit';

export enum MERGE_OPTIONS {
  ATTACHMENTS = 'mergeAttachments',
  ACTIVITIES = 'mergeActivities',
  NOTES = 'mergeNotes',
  MESSAGES = 'mergeMessages',
}

export type MergeOptions = {
  [MERGE_OPTIONS.ACTIVITIES]: boolean;
  [MERGE_OPTIONS.ATTACHMENTS]: boolean;
  [MERGE_OPTIONS.MESSAGES]: boolean;
  [MERGE_OPTIONS.NOTES]: boolean;
};

type MergeValues = {
  selectedRowIndex: number | null;
  mergeOptions: MergeOptions;
  mergeStatus: any;
  mergeReason: string | null;
  primarySrId: number | string | null;
};

const initialState = {
  selectedRowIndex: null,
  mergeOptions: {
    [MERGE_OPTIONS.ACTIVITIES]: false,
    [MERGE_OPTIONS.ATTACHMENTS]: false,
    [MERGE_OPTIONS.MESSAGES]: false,
    [MERGE_OPTIONS.NOTES]: false,
  },
  mergeStatus: null,
  mergeReason: null,
  primarySrId: null,
} as MergeValues;

export const mergeSlice = createSlice({
  name: 'mergeRequests',
  initialState,
  reducers: {
    setSelectedRowIndex: (state, { payload }) => {
      state.selectedRowIndex = payload;
    },
    toggleMergeOption: (state, { payload }) => {
      state.mergeOptions = {
        ...state.mergeOptions,
        [payload]: !state.mergeOptions?.[payload],
      };
    },
    setMergeStatus: (state, { payload }) => {
      state.mergeStatus = payload;
    },
    setMergeReason: (state, { payload }) => {
      state.mergeReason = payload;
    },
    setPrimarySrId: (state, { payload }) => {
      state.primarySrId = payload;
    },
    clearMerge: (state) => {
      state.selectedRowIndex = null;
      state.mergeOptions = initialState.mergeOptions;
      state.mergeStatus = null;
      state.mergeReason = null;
      state.primarySrId = null;
    },
  },
});

export const { setSelectedRowIndex, setMergeReason, setMergeStatus, toggleMergeOption, setPrimarySrId, clearMerge } =
  mergeSlice.actions;

type State = {
  mergeRequests: MergeValues;
};

export const selectMergeOptions = (state: State): MergeOptions => state.mergeRequests.mergeOptions;
export const selectSelectedRowIndex = (state: State): number | null => state.mergeRequests.selectedRowIndex;
export const selectMergeStatus = (state: State): any => state.mergeRequests.mergeStatus;
export const selectMergeReason = (state: State): string | null => state.mergeRequests.mergeReason;
export const selectIsMergeDisabled = (state: State): boolean => {
  const { mergeReason, mergeStatus, selectedRowIndex } = state.mergeRequests;
  return (!mergeReason?.length && !mergeReason?.trim()?.length) || !mergeStatus || selectedRowIndex === null;
};

export default mergeSlice.reducer;
export const mergeSliceName = mergeSlice.name;

import MessagePrompt from 'common/components/messagePrompt';
import useTexts from '../useTexts';

const LogoutPrompt = ({ open, onClose, handleSave }) => {
  const {
    logoutPromptTitle,
    logoutPromptDescription,
    logoutPromptOkBtnText,
  } = useTexts();
  
  const handleLogout = () => {
    handleSave(true);
    onClose();
  };

  return (<MessagePrompt
    open={open}
    title={logoutPromptTitle}
    btnOkText={logoutPromptOkBtnText}
    onOkClick={handleLogout}
    showCancelBtn
    onClose={onClose}
  >
    {logoutPromptDescription}
  </MessagePrompt>);
};

export default LogoutPrompt;
import { useCallback, useRef } from 'react';

export const COLUMN_WIDTHS_LS_KEY = 'agGridColumnWidths';

const useCacheColumnsWidth = (): { handleColumnResized: (event) => void } => {
  const columnWidthsRef = useRef({});
  const handleColumnResized = useCallback((event) => {
    if (event.finished) {
      const colId = event.column.getColId();
      const newWidth = event.column.getActualWidth();
      columnWidthsRef.current[colId] = newWidth;
      const prevValue = JSON.parse(localStorage.getItem(COLUMN_WIDTHS_LS_KEY) || '{}');
      const updatedWidths = {
        ...prevValue,
        ...columnWidthsRef?.current,
      };
      localStorage.setItem(COLUMN_WIDTHS_LS_KEY, JSON.stringify(updatedWidths));
    }
  }, []);

  return { handleColumnResized };
};

export default useCacheColumnsWidth;
